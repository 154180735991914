import Vue from 'vue';
import App from './App'
import Cookies from 'js-cookie'
import store from './store'

import router from './router'
import Web3 from 'web3'

import tool from "./utils/tool";
Vue.use(tool);

import {
  initSocket
} from "@/utils/socket.js";
Vue.prototype.$socket = initSocket();

import Element, {
  Message
} from 'element-ui'
Vue.use(Element, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
})

import './utils/flexible.js'




import '@/styles/main.scss' // global css
import '@/styles/index.css' // global css



router.beforeEach((to, from, next) => {

  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next()
})


new Vue({
  // el: '#app',
  router,
  store,
  render: h => h(App),

  mounted() {
    // document.dispatchEvent(new Event('render-event'))
    document.dispatchEvent(new Event('custom-render-trigger')) // 预渲染
  },
}).$mount('#app')