<template>
  <div id="layout" ref="layout">
    <!-- <Popup class="oh_popup" /> -->
    <div class="main">
      <!-- <div class="header">
        <Navigation class="navigation" ref="nav" /> 
      </div>-->
      <keep-alive :key="new Date() + ''">
        <transition enter-active-class="animate__animated animate__fadeIn">
          <router-view></router-view>
        </transition>
      </keep-alive>
    </div>
  </div>
</template>

<script>
// import Navigation from "@/components/navigation/index.vue";
export default {
  name: "Layout",
  metaInfo: { title: "" },
  data() {
    return {};
  },
  // components: {  Navigation },
  watch: {
    
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 1024px) {
  .navigation {
    display: none;
  }
}
// 移动端样式
@media (max-width: 1024px) {
  .navigation {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
  }
}
</style>